import React from 'react'
import {Layout, OffersBanners} from 'widgets'
import {SEO, Input, Icon, Textarea, Button} from 'components'
import {FaqBanner} from 'images'

type Form = {
    name: string,
    phone: string,
    email: string,
    message: string
}
const Faq = () => {
    const IFormState = {
        name: '',
        phone: '',
        email: '',
        message: ''
    }
    const [search, setSearch] = React.useState<string>('')
    const [form, setForm] = React.useState<Form>(IFormState)
    const faqs = [
        {
            title: '¿Por qué contratar con Compara Assist?',
            text: 'En Compara Assist nos hemos ocupado en ubicar los mejores proveedores de asistencia médica de viaje que brinden diferentes cualidades entre cobertura y precio para poder cumplir con las exigencias de nuestros clientes.'
        },
        {
            title: '¿Cómo recibo los certificados que adquirí por la página?',
            text: 'Una vez finalizado el proceso de pago y que este mismo sea confirmado, los certificados se proceden a emitir con los datos del viaje y estos se hacen llegar al correo electrónico que el comprador indicó. Solo necesitas guardarlos en tu móvil o si deseas puedes imprimirlo en caso que se requiera para una visa u otro requerimiento.'
        },
        {
            title: '¿Qué hacer en caso de algún siniestro durante el viaje?',
            text: 'En los certificados que llegaron a tu correo se indican los datos de contacto del proveedor de servicio que seleccionaste, debes contactarte inmediatamente antes de auto asistirse o incurrir en cualquier gasto por los medios de contacto brindados.'
        },
        {
            title: '¿Puedo anular mi cobertura?',
            text: 'Si deseas anular, cancelar y/o suspender el servicio, debes contactarte con nosotros antes de que el certificado entre en vigencia, notificando el motivo de tu solicitud para poder comunicarle a la compañía tu decisión, los tiempos de reintegros o políticas del mismo dependerán de la compañía prestadora del servicio.'
        },
        {
            title: '¿Puedo cambiar las fechas de viaje o hacer una modificación del servicio que ya adquirí?',
            text: 'Sí, pero debes hacerlo antes de que entre en vigencia el certificado, con respecto a las fechas que inicialmente adquiriste y debe corresponder al mismo pasajero a quien se le emitió, esto puede ocasionar gastos extras tanto administrativos como de diferencias de días o planes.'
        },
        {
            title: '¿Qué pasa si ya mi certificado entró en vigencia y quiero hacer cambios o anulaciones?',
            text: 'No podemos hacer anulaciones, reintegros ni modificaciones de certificados que entraron en vigencia. Por lo cual, debes comunicarte con la compañía prestadora para notificar tu decisión y ver si dentro de las cláusulas procede o no algún tipo de reintegro.'
        },
    ]
    const change = (key: keyof Form, value: string) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };
    return(
        <Layout>
            <SEO
                title="Preguntas Frecuentes sobre Compara Assist"
                description="¿No sabes cómo funciona nuestro comparador? ¿Tienes sobre cómo solicitar tu seguro médico? En este espacio tendrás todas las respuestas."
                keywords="Preguntas frecuentes, dudas sobre Compara, cambios o anulación, anulación de seguro,  comparador de seguros, preguntas frecuentes comparador"
            />
            <div className="faq">
                <div
                    className="banner"
                    style={{
                        background: `linear-gradient(#190c2f, rgba(25, 12, 47, 0.7)), url(${FaqBanner})`
                    }}
                >
                    <div className="w-100 text-center titles p-1">
                        <h2 className="text-white">
                            ¿Por qué contratar con Compara Assist?
                        </h2>
                        <h2 className="text-white">
                            ¿Tienes dudas sobre cómo funciona nuestro comparador?
                        </h2>
                        <p className="text-pink">
                            Aquí encontrarás toda la información que necesitas para adquirir la cobertura ideal para ti ¡tú decides!
                        </p>
                        {/* <div className="container w-100">
                            <div className="input-container">
                                <Input
                                    name="search"
                                    onChange={(value: string) => setSearch(value)}
                                    value={search}
                                    placeholder="¿Cual es tu duda?"
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="w-100 text-center subtitle-container">
                    <div className="icon-container">
                        <Icon
                            name="comments icon-bordered p-2"
                        />
                    </div>
                    <h3 className="text-purple">
                        Preguntas&nbsp;
                        <span className="text-pink">
                            Frecuentes
                        </span>
                    </h3>
                </div>
                <div className="faqs">
                    <div id="accordion">
                        {
                            faqs.map((element, i) => {
                                return (
                                    <div
                                        className="card"
                                        key={i}
                                    >
                                        <div className="card-header" id={"headingOne"+i}>
                                        <h5 className="mb-0">
                                            <button className="btn btn-link text-purple font-weight-bold text-decoration-none text-left" data-toggle="collapse" data-target={"#collapseOne"+i} aria-expanded="true" aria-controls={"collapseOne"+i}>
                                                {element.title}
                                            </button>
                                        </h5>
                                        </div>

                                        <div id={"collapseOne"+i} className={"collapse"+(!i ? 'show': '')} aria-labelledby={"headingOne"+i} data-parent="#accordion">
                                            <div className="card-body">
                                                {element.text}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="w-100 bg-white form">
                    <div className="container">
                        <div className="form-container text-center">
                            <h4 className="text-purple">
                                ¿Tienes más dudas sobre nuestro Comparador?
                            </h4>
                            <div className="form-inputs">
                                <Input
                                    name="name"
                                    placeholder="Nombre"
                                    onChange={(value: string) => change('name', value)}
                                    value={form.name}
                                />
                                <Input
                                    name="email"
                                    placeholder="E-Mail"
                                    onChange={(value: string) => change('email', value)}
                                    value={form.email}
                                />
                                <Input
                                    name="phone"
                                    placeholder="Telefono"
                                    onChange={(value: string) => change('phone', value)}
                                    value={form.phone}
                                />
                                <Textarea
                                    name="message"
                                    onChange={(value: string) => change('message', value)}
                                    value={form.message}
                                    placeholder="¿Como podemos ayudarte?, Escribe aqui tu mensaje"
                                />
                                <div className="container">
                                    <div className="button-container">
                                        <Button
                                            className="pink"
                                            label="Enviar mi solicitud"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OffersBanners
                
                />
            </div>
        </Layout>
    )
}

export default Faq